<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.use") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        class="table-padding-2"
        fixed-header
        height="calc(100vh - 400px)"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader">
                <InputFilter
                  :label="$t('labels.customer')"
                  :placeholder="$t('labels.customer')"
                  name="company_name"
                  sort-name="company_name"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 120px">
                <SelectFilter
                  :options="statusOptions"
                  :label="$t('labels.status')"
                  :placeholder="$t('labels.status')"
                  name="status"
                  sort-name="status"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="text-center">
              <td>{{ item.company_name }}</td>
              <td>{{ item.status ? "Yes" : "No" }}</td>
              <td>
                <v-switch
                  class="switch-center mt-0 pt-0"
                  v-model="item.is_active"
                  @change="switchUse(item)"
                  :key="`item_${item.id}`"
                  hide-details
                  dense
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="pt-3">
        <v-pagination
          v-model="page"
          v-if="totalPage > 1"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
export default {
  name: "PosUse",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
  },
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filters: {},
    items: [],
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    statusOptions: [...YES_NO_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getCustomerUsing();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    getCustomerUsing() {
      httpClient
        .post("/packaging/v1/customer-using-list", {
          id_wp_packaging: this.updatingItem.id,
        })
        .then(({ data }) => {
          const usingIds = [...data].map((item) => item.id_customer);
          this.getList(usingIds);
        });
    },
    getList: debounce(function (usingIds) {
      httpClient
        .post("/customer/v1/list", {
          ...this.filters,
          page: this.page,
          id_warehouse: this.updatingItem.id_warehouse,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.totalActive = data.totalActive;
          this.items = [...data.rows].map((r) => {
            r.status = r.identity_status === 10;
            r.is_active = usingIds.includes(r.id);
            return r;
          });
        });
    }, 500),
    async switchUse(item) {
      if (!this.checkPermission(["super_admin"])) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/packaging/v1/customer-using-update", {
          id_wp_packaging: this.updatingItem.id,
          id_customer: item.id,
          status: item.is_active,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
